<template>
  <div class="page">
    <div class="title">月工资表</div>

    <!-- filter -->
    <!-- <div class="filter_box">
      <div class="filter_search">
        <div class="custom_button plain no_select"
          @click="exportinfo">
          导出
        </div>
      </div>
    </div> -->

    <!-- 导入五险一金 -->
    <input
      ref="fileInput"
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
      @change="importRoster"
      @click="
        (event) => {
          event.target.value = null;
        }
      "
      style="width: 0; height: 0; position: absolute"
    />

    <!-- 导入补贴扣款 -->
    <input
      ref="fileInputs"
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
      @change="importSubsidy"
      @click="
        (event) => {
          event.target.value = null;
        }
      "
      style="width: 0; height: 0; position: absolute"
    />

    <!-- table -->
    <el-table
      ref="table"
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%"
      @selection-change="handleCurrentChange"
    >
      <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
      <el-table-column prop="month" label="工资时间"></el-table-column>
      <el-table-column prop="create_time" label="统计时间"></el-table-column>
      <el-table-column prop="total_num" label="包含员工人数"></el-table-column>
      <el-table-column prop="salary_total" label="总金额"></el-table-column>
      <el-table-column
        prop="social_status_value"
        label="五险一金状态"
      ></el-table-column>
      <el-table-column
        prop="social_time"
        label="五险一金导入时间"
      ></el-table-column>
      <el-table-column
        prop="subsidy_status_value"
        label="补贴状态"
      ></el-table-column>
      <el-table-column
        prop="subsidy_time"
        label="补贴导入时间"
      ></el-table-column>
      <el-table-column
        prop="sure_status_value"
        label="确认状态"
      ></el-table-column>
      <el-table-column prop="status_value" label="发放状态"></el-table-column>
      <el-table-column prop="send_time" label="发放时间"></el-table-column>
      <el-table-column label="操作" width="130" fixed="right">
        <template v-slot:default="scope">
          <el-dropdown size="mini" split-button type="primary">
            操作
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="checkdetail(scope.row.id, scope.row.sure_status)"
                >明细</el-dropdown-item
              >
              <el-dropdown-item @click.native="exportinfo(scope.row.id)"
                >导出</el-dropdown-item
              >
              <el-dropdown-item
                v-if="scope.row.sure_status == 0"
                @click.native="confirm(scope.row.id)"
                >确认</el-dropdown-item
              >
              <el-dropdown-item
                v-if="scope.row.status == 0 && scope.row.sure_status == 1"
                @click.native="pay(scope.row.id)"
                >发薪</el-dropdown-item
              >
              <el-dropdown-item
                v-if="scope.row.sure_status == 0"
                @click.native="getsocialfundlist(scope.row.id)"
                >导出五险一金</el-dropdown-item
              >
              <el-dropdown-item
                v-if="scope.row.sure_status == 0"
                @click.native="chooseExcel(scope.row.id)"
                >导入五险一金</el-dropdown-item
              >
              <el-dropdown-item
                v-if="scope.row.sure_status == 0"
                @click.native="exportSubsidyMoneyList(scope.row.id)"
                >导出补贴扣款</el-dropdown-item
              >
              <el-dropdown-item
                v-if="scope.row.sure_status == 0"
                @click.native="importSubsidyMoneyList(scope.row.id)"
                >导入补贴扣款</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.limit"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>

    <el-dialog
      title="月工资明细"
      :visible.sync="dialogVisible"
      width="80%"
      @close="editId = ''"
    >
      <el-table
        :data="salarydetail"
        :header-cell-style="tableHeaderStyle"
        :row-class-name="tableRowClassName"
        :cell-style="tableCellStyle"
        style="width: 100%"
        fit
        border
      >
        <el-table-column
          prop="sure_status"
          label="操作"
          v-if="editSureStatus == 0"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click.prevent="editMoneyInfo(scope)"
              >{{ scope.row.id == editId ? "修改" : "编辑" }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="real_name" label="姓名" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="job_number" label="工号"></el-table-column>
        <el-table-column
          prop="payroll_company"
          label="发薪公司"
        ></el-table-column>
        <el-table-column prop="company_name" label="公司名称"></el-table-column>
        <el-table-column
          prop="department_name"
          label="单位"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="duty_name" label="项目"></el-table-column>
        <el-table-column prop="station_name" label="岗位名称"></el-table-column>
        <el-table-column prop="post_level" label="岗位类型"></el-table-column>
        <el-table-column prop="work_type" label="用工类别	"></el-table-column>
        <el-table-column
          prop="attendance_standard"
          label="出勤标准"
        ></el-table-column>
        <el-table-column
          prop="week_overtime_standard"
          label="周末加班标准"
        ></el-table-column>
        <el-table-column
          prop="week_overtime_day"
          label="周末加班天数"
        ></el-table-column>
        <!-- <el-table-column
          prop="week_overtime_salary"
          label="周末加班应得工资"
        ></el-table-column> -->
        <el-table-column
          prop="add_replace_wages_standard_type"
          label="替岗类型"
        ></el-table-column>
        <el-table-column
          prop="add_replace_wages_standard_first"
          label="加点替岗1工资标准"
        ></el-table-column>
        <el-table-column
          prop="add_replace_wages_standard_first_day"
          label="替岗1天数	"
        ></el-table-column>
        <el-table-column
          prop="add_replace_wages_standard_second"
          label="加点替岗2工资标准"
        ></el-table-column>
        <el-table-column
          prop="add_replace_wages_standard_second_day"
          label="替岗2天数"
        ></el-table-column>
        <el-table-column
          prop="add_replace_wages_standard_third"
          label="加点替岗3工资标准"
        ></el-table-column>
        <!-- <el-table-column prop="add_replace_wages_standard_third_day" label="替岗3天数"></el-table-column> -->
        <el-table-column label="替岗工资" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.add_replace_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.add_replace_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="paid_vacation"
          label="有薪假天数"
        ></el-table-column>
        <el-table-column
          prop="normal_work_salary"
          label="正常工作时间工资"
        ></el-table-column>
        <el-table-column label="法定加班费" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.legal_overtime_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.legal_overtime_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column label="伙食补贴" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.fool_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.fool_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column label="技术津贴" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.technology_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.technology_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column label="异地津贴" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.place_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.place_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出差津贴" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.business_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.business_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="高温津贴">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.high_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.high_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="生日慰问津贴">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.birthday_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.birthday_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="介绍费">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.introduce_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.introduce_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="过节费">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.holiday_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.holiday_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="考核奖励	">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.assess_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.assess_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="其他">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.others_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.others_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="考核扣款">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.examine_deduct_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.examine_deduct_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="其他扣款">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.other_deduct_salary"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.other_deduct_salary }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="stay_away_salary"
          width="100px"
          label="事假扣款"
        ></el-table-column>
        <el-table-column
          prop="without_salary"
          width="100px"
          label="旷工扣款"
        ></el-table-column>
        <el-table-column
          prop="other_salary"
          label="婚丧产护扣款"
          width="100px"
        ></el-table-column>
        <el-table-column
          prop="sick_salary"
          width="100px"
          label="病假扣款"
        ></el-table-column>
        <el-table-column
          prop="injury_salary"
          width="100px"
          label="公伤扣款"
        ></el-table-column>
        <el-table-column
          width="100px"
          prop="white_amount"
          label="偿还白条"
        ></el-table-column>
        <el-table-column
          prop="deduction_salary"
          width="100px"
          label="考勤扣款总金额"
        ></el-table-column>
        <el-table-column
          prop="personal_tax"
          width="100px"
          label="个税"
        ></el-table-column>
        <el-table-column width="100px" label="养老保险（个人）">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_endowment_insurance_per"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.deduct_endowment_insurance_per }}</span>
          </template>
        </el-table-column>
        <el-table-column label="养老保险（单位）" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_endowment_insurance_com"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.deduct_endowment_insurance_com }}</span>
          </template>
        </el-table-column>
        <el-table-column label="失业保险（个人）" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_unemployment_insurance_per"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{
              scope.row.deduct_unemployment_insurance_per
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="失业保险（单位）" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_unemployment_insurance_com"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{
              scope.row.deduct_unemployment_insurance_com
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="医疗保险（个人）" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_medical_insurance_per"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.deduct_medical_insurance_per }}</span>
          </template>
        </el-table-column>
        <el-table-column label="医疗保险（单位）" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_medical_insurance_com"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.deduct_medical_insurance_com }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="deduct_injury_insurance_per"
          label="工伤保险（个人）"
        ></el-table-column> -->
        <el-table-column label="工伤保险（单位）" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_injury_insurance_com"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.deduct_injury_insurance_com }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="deduct_maternity_insurance_per"
          label="生育保险（个人）"
        ></el-table-column> -->
        <el-table-column label="生育保险（单位）" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_maternity_insurance_com"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.deduct_maternity_insurance_com }}</span>
          </template>
        </el-table-column>
        <el-table-column label="补充医疗（单位）" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_supplement_medical_com"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.deduct_supplement_medical_com }}</span>
          </template>
        </el-table-column>
        <el-table-column label="补充医疗（个人）" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_supplement_medical_per"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.deduct_supplement_medical_per }}</span>
          </template>
        </el-table-column>
        <el-table-column label="重疾（单位）" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_serious_disease_com"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.deduct_serious_disease_com }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="deduct_total_insurance_per"
          label="个人五险总扣款"
          width="100px"
        ></el-table-column>
        <el-table-column
          prop="deduct_total_insurance_com"
          label="单位五险总扣款	"
          width="100px"
        ></el-table-column>
        <el-table-column label="个人公积金扣除总金额	" width="100px">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_total_fund_per"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.deduct_total_fund_per }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="单位公积金扣除总金额	">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.deduct_total_fund_com"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.deduct_total_fund_com }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="salary_base" label="基本月薪"></el-table-column>
        <el-table-column prop="sub_total" label="实发小计"></el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <el-input
              type="textarea"
              v-model="scope.row.remark"
              v-if="scope.row.sure_status == 0 && scope.row.id == editId"
            ></el-input>
            <span v-else>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="添加时间"></el-table-column>
        <el-table-column prop="update_time" label="更新时间"></el-table-column>
      </el-table>

      <el-pagination
        layout="prev, pager, next"
        :total="page1.total"
        :page-size="page1.limit"
        :current-page.sync="page1.page"
        @current-change="getDetailCurrent"
      >
      </el-pagination>
    </el-dialog>
    <!-- 发放渠道 -->
    <el-dialog title="发放渠道" :visible.sync="dialogVisible1" width="30%">
      <el-form>
        <el-form-item>
          <el-select v-model="form.name">
            <el-option
              v-for="item in banklist"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="confirmpay">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  inject: ["reload"],
  data() {
    return {
      search: {
        keyword: "",
      },
      tableData: [],
      page: {
        limit: 10,
        page: 1,
        total: 0,
      },
      page1: {
        limit: 10,
        page: 1,
        total: 0,
        currentId: 0,
      },
      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
      salarydetail: [],
      dialogVisible: false,
      dialogVisible1: false,
      banklist: [
        {
          id: 1,
          name: "平安银行",
        },
        {
          id: 2,
          name: "中国银行",
        },
        {
          id: 3,
          name: "广发银行",
        },
      ],
      form: {
        name: "",
        id: "",
      },
      uploadid: "",
      // 编辑工资明细的id
      editId: "",
      editSureStatus: 1,
    };
  },
  created() {
    this.loadData = this.loadDataFactory();
    this.loadData();
  },
  methods: {
    ...mapActions(["uploadFile"]),
    ...mapActions("enterprise", [
      "getDepartmentList",
      "addDepartment",
      "editDepartment",
      "delDepartment",
    ]),
    ...mapActions("pay", [
      "loadsalarylist",
      "getsalarydetail",
      "getexportinfo",
      "confirmsalary",
      "paywage",
      "getsocialfund",
      "uploadsocialfund",
      "exportsubsidymoney",
      "importsubsidymoney",
      "postEditSalary",
    ]),
    // 编辑保存修改月工资明细
    editMoneyInfo(scope) {
      console.log(JSON.parse(JSON.stringify(scope.row)));
      let {
        id,
        fool_salary,
        technology_salary,
        place_salary,
        business_salary,
        high_salary,
        introduce_salary,
        holiday_salary,
        birthday_salary,
        assess_salary,
        others_salary,
        subsidy_deduct_remark,
        examine_deduct_salary,
        other_deduct_salary,
        deduct_endowment_insurance_per,
        deduct_unemployment_insurance_per,
        deduct_medical_insurance_per,
        deduct_maternity_insurance_per,
        deduct_endowment_insurance_com,
        deduct_unemployment_insurance_com,
        deduct_medical_insurance_com,
        deduct_injury_insurance_com,
        deduct_maternity_insurance_com,
        deduct_supplement_medical_com,
        deduct_serious_disease_com,
        deduct_total_fund_per,
        deduct_total_fund_com,
        deduct_supplement_medical_per,
        remark,
        add_replace_salary,
        legal_overtime_salary,
      } = scope.row;
      if (id == this.editId) {
        let loading = this.$loading();
        console.log("保存修改");
        let params = {
          id,
          fool_salary,
          technology_salary,
          place_salary,
          business_salary,
          high_salary,
          introduce_salary,
          holiday_salary,
          birthday_salary,
          assess_salary,
          others_salary,
          subsidy_deduct_remark,
          examine_deduct_salary,
          other_deduct_salary,
          deduct_endowment_insurance_per,
          deduct_unemployment_insurance_per,
          deduct_medical_insurance_per,
          deduct_maternity_insurance_per,
          deduct_endowment_insurance_com,
          deduct_unemployment_insurance_com,
          deduct_medical_insurance_com,
          deduct_injury_insurance_com,
          deduct_maternity_insurance_com,
          deduct_supplement_medical_com,
          deduct_serious_disease_com,
          deduct_total_fund_per,
          deduct_total_fund_com,
          deduct_supplement_medical_per,
          remark,
          add_replace_salary,
          legal_overtime_salary,
        };
        console.log("请求参数", params);
        this.postEditSalary(params)
          .then((res) => {
            console.log(res);
            this.$message.success(res.data.msg);
            if (res?.data?.code) {
              this.editId = "";
            }
            loading.close();
          })
          .catch((err) => {
            // console.log(err);
            this.$message.error(err.data.msg);
            loading.close();
          });
      } else {
        this.editId = id;
      }
    },

    loadDataFactory() {
      let params = {};
      return () => {
        params.page = this.page.page;
        params.limit = this.page.limit;
        let loading = this.$loading();
        this.loadsalarylist(params)
          .then((res) => {
            console.log(res);
            this.tableData = res.data.data.list;
            this.page.total = res.data.data.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            loading.close();
          });
      };
    },
    checkdetail(id, sure_status) {
      console.log(id, sure_status);
      let loading = this.$loading();
      this.editSureStatus = sure_status;
      this.page1.currentId = id;
      let params = {};
      params.id = id;
      params.page = this.page1.page;
      params.size = this.page1.limit;
      console.log("分页参数", params);
      this.getsalarydetail(params)
        .then((res) => {
          console.log(res, "明细分页");
          this.salarydetail = res.data.data.list;
          this.page1.total = res.data.data.total;
          this.dialogVisible = true;
          loading.close();
        })
        .catch((e) => {
          this.$message.error(e);
        });
    },
    //明细分页
    getDetailCurrent(e) {
      let loading = this.$loading();
      let params = {};
      params.id = this.page1.currentId;
      params.page = e;
      params.size = this.page1.limit;
      this.getsalarydetail(params)
        .then((res) => {
          console.log(res, "明细分页");
          this.salarydetail = res.data.data.list;
          this.page1.total = res.data.data.total;
          this.dialogVisible = true;
          loading.close();
        })
        .catch((e) => {
          this.$message.error(e);
        });
    },
    tableRowClassName({ row }) {
      if (row.is_red == 1) {
        return "warm-row";
      }
      return "";
    },
    //导出数据表
    exportinfo(id) {
      // if(!this.$refs.table.store.states.selection[0]) {
      //   this.$message.error('请选中要导出的数据')
      //   return;
      // }
      let params = {};
      // let selectedItems = this.$refs.table.store.states.selection
      params.id = id;
      params.type = 1;
      params.name = "工资信息.xlsx";
      this.getexportinfo(params);
    },
    //一次只能选中一条数据
    handleCurrentChange(val) {
      this.currentRow = val;
      this.tableData.forEach((item) => {
        if (val[val.length - 1] === item) {
          this.$refs.table.toggleRowSelection(item, true);
        } else {
          this.$refs.table.toggleRowSelection(item, false);
        }
      });
    },
    confirm(id) {
      this.confirmsalary(id)
        .then((res) => {
          console.log(res);
          this.loadData();
        })
        .catch((e) => {
          this.$message.error({
            message: e.msg,
            showClose: true,
          });
        });
    },
    // 确认发薪操作
    pay(id) {
      this.dialogVisible1 = true;
      this.form.id = id;
    },
    confirmpay() {
      let params = this.form;
      this.paywage(params)
        .then((res) => {
          console.log(res);
          this.dialogVisible1 = false;
          this.loadData();
        })
        .catch((e) => {
          this.$message.error({
            message: e.msg,
            showClose: true,
          });
        });
    },

    // 个人单独计算五险一金
    getsocialfundlist(id) {
      let params = {};
      params.id = id;
      this.getsocialfund(params).then((res) => {
        // 将二进制流转为blob
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
          window.navigator.msSaveBlob(blob, "五险一金");
        } else {
          // 创建新的URL并指向File对象或者Blob对象的地址
          const blobURL = window.URL.createObjectURL(blob);
          // 创建a标签，用于跳转至下载链接
          const tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = blobURL;
          tempLink.setAttribute("download", "五险一金");
          // 兼容：某些浏览器不支持HTML5的download属性
          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }
          // 挂载a标签
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          // 释放blob URL地址
          window.URL.revokeObjectURL(blobURL);
        }
      });
    },

    // 计算补贴扣款
    exportSubsidyMoneyList(id) {
      let params = {};
      params.id = id;
      this.exportsubsidymoney(params).then((res) => {
        // 将二进制流转为blob
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // 上传.xslx格式
        });
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
          window.navigator.msSaveBlob(blob, "补贴扣款");
        } else {
          // 创建新的URL并指向File对象或者Blob对象的地址
          const blobURL = window.URL.createObjectURL(blob);
          // 创建a标签，用于跳转至下载链接
          const tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = blobURL;
          tempLink.setAttribute("download", "补贴扣款");
          // 兼容：某些浏览器不支持HTML5的download属性
          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }
          // 挂载a标签
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          // 释放blob URL地址
          window.URL.revokeObjectURL(blobURL);
        }
      });
    },

    /**
     * 选择excel
     */
    chooseExcel(id) {
      this.$refs.fileInput.click();
      this.uploadid = id;
    },
    // 导入补贴扣款excel表
    importSubsidyMoneyList(id) {
      this.$refs.fileInputs.click();
      // console.log('this的proxy',this.$ref.fileInput);
      // this.importsubsidymoney
      this.uploadid = id;
    },
    /**
     * 导入考勤表
     */
    async importRoster(file) {
      console.log(this.$store.getters);
      let loading = this.$loading();
      let params = {};
      params.id = this.uploadid;
      // try {
      let link = (
        await this.uploadFile({
          file: file.target.files[0],
          type: "salary_xls",
        })
      ).data.data.link;
      params.link = link;
      let res = await this.uploadsocialfund(params);
      this.uploadid = "";
      loading.close();
      console.log(res, "导入五险一金");
      // this.$message.success(res.data.msg);
      let error_remark = res.data.data.error_remark;
      if (error_remark.length > 0) {
        this.$confirm(error_remark.join(), "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        });
      } else {
        this.$alert(res.data.msg, "成功", {
          confirmButtonText: "确定",
          type: "success",
        });
      }
      this.loadData();
      // } catch (e) {
      //   loading.close();
      //   console.error(e);
      //   this.$message.error({
      //     message: e.msg,
      //     showClose: true,
      //   });
      // }
    },

    // 计算补贴扣除方法
    async importSubsidy(file) {
      console.log(this.$store.getters);
      let loading = this.$loading();
      let params = {};
      params.id = this.uploadid;
      // try {
      let link = (
        await this.uploadFile({
          file: file.target.files[0],
          type: "salary_xls",
        })
      ).data.data.link;
      params.link = link;
      let res = await this.importsubsidymoney(params);
      this.uploadid = "";
      loading.close();
      console.log(res, "补贴扣款");
      // this.$message.success(res.data.msg);
      let error_remark = res.data.data.error_remark;
      if (error_remark.length > 0) {
        this.$confirm(error_remark.join(), "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        });
      } else {
        this.$alert(res.data.msg, "成功", {
          confirmButtonText: "确定",
          type: "success",
        });
      }
      this.loadData();
      // } catch (e) {
      //   loading.close();
      //   console.error(e);
      //   // this.$message.error({
      //   //   message: e.msg,
      //   //   showClose: true,
      //   // });
      //   this.$confirm(error_remark.join(), "提示", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "error",
      //   });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100% - 50px);
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .el-date-editor {
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }
  }

  .filter_search {
    display: flex;
    align-items: center;
    margin-left: auto;

    .filter_search_select {
      width: 80px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }

      ::v-deep .el-input__icon {
        line-height: 36px;
      }
    }

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.operations {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    color: #406bff;
    white-space: nowrap;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    > div {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.s_operation {
  color: #4db9d5;
}
.class_name {
  overflow-y: auto;
}
</style>
